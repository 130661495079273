// Brand
$brand-primary:       #000091;
$brand-secondary:     #000000;
$brand-bgk-highlight: #d9e8ff;
$brand-chef-blue:     #e3eeff;
$brand-coral:         #ff7352;
$brand-crimson-red:   #ef144b;
$brand-dark:          #373737;
$brand-dark-gray:     #525252;
$brand-gray:          #7e7e7e;
$brand-light-gray:    #dddddd40;
$brand-light:         #f6f7fd;
$brand-platinum:      #dddddd;
$brand-red:           #e1000f;
$brand-royal-blue:    #0425b9;
$brand-skyblue-opacity:#d9e8ff;
$brand-white:         #ffffff;
$brand-wisp-pink:     #ffeae5;

$border-color:        #e6eaed;

// Type
$font-family-primary:     'Outfit-Regular', Helvetica, Arial, sans-serif;
$font-family-secondary:   'Outfit-Regular', serif;
$font-family-light:       'Outfit-Light', Helvetica, Arial, sans-serif;
$font-family-bold:        'Outfit-Bold', Helvetica, Arial, sans-serif;
$font-size-base:           18px;

$font-size-xxxs:            8px;
$font-size-xxs:             12px;
$font-size-xs:              14px;
$font-size-sm:              16px;
$font-size-smd:             20px;
$font-size-md:              22px;
$font-size-slg:             24px;
$font-size-lg:              28px;
$font-size-lgmd:            30px;
$font-size-xlg:             32px;
$font-size-xl:              34px;
$font-size-xlmd:            36px;
$font-size-xxl:             40px;
$font-size-xxxl:            48px;


$font-weight-extra-bold:    900;
$font-weight-bold:          700;
$font-weight-regular:       400;
$font-weight-light:         300;

$letter-spacing:            0.07px;
$line-height-mb-const:      1.455;
$line-height-const:         1.333;
$line-height-computed:      $font-size-base * $line-height-const;

$text-color: $brand-secondary;
$text-muted: #7E8083;
$link-color: $brand-primary;
$link-hover-color: darken($brand-primary, 10%);

$container-sm:    720px;
$container-md:    860px;
$container-lg:    1080px;
$container-xlg:   1274px;
$container-xl:    1440px;

$grid-gutter-width: 40px;

// Buttons
$btn-border-radius-base: 0;
$btn-font-family:       $font-family-bold;
$btn-font-size:         $font-size-xs;
$btn-font-weight:       unset;
$btn-primary-border:    $brand-primary;

// Cards
$card-default-background-color: transparent;
$card-border-color: #eee;
$card-border-width: 0;
$card-box-shadow: none;
$card-btn-prototype: '.link';
$card-link-font-weight: 400;
$card-spacer-x: 12px;
$card-spacer-y: 12px;

// Features.
$feature-icon-width: 40px;
$feature-icon-border-color: transparent;

// Nav
$navbar-client-height:           117px;

// Sections
$section-bg: transparent;
$section-block-bg: none;
$section-block-position: relative;
$section-btn-prototype: '.btn-default';
$section-cap-x: 0px;
$section-cap-y: 48px;
$section-header-bg: none;
$section-footer-bg: none;
$section-margin: 0;
$section-position: relative;
$section-prototype: 'container';
$section-hero-text-color: #fff;
$section-spacer-x: 0px;
$section-spacer-y: 14px;
$section-text-color: $text-color; // .section-text {} font color.
$section-text-color-muted: $text-muted; // .section-text--secondary {}
$section-title-border: 1px solid #eee;
$section-title-font-family: $font-family-bold;
$section-title-font-size: $font-size-xxl;
$section-title-font-weight: unset;
$section-title-line-height: 48px;
$section-title-text-align: left;

// Webidentities
$webidentity-width: 36px;

// RS colors
$brand-dailymotion:     #13B1FC;
$brand-facebook:        #3b5998;
$brand-flickr:          #ff0084;
$brand-googleplus:      #dd4b39;
$brand-instagram:       #e1306c;
$brand-linkedin:        #0077b5;
$brand-pinterest:       #bd081c;
$brand-rss:             #f26522;
$brand-tiktok:          #000000;
$brand-twitter:         #000000;
$brand-viadeo:          #f07355;
$brand-youtube:         #ff0000;
$brand-sharing:         #ED4D4D;
$brand-soundcloud:      #ff8800;
$brand-email:           $brand-primary;

$webidentities: (
  'dailymotion': (
    'bg-color': $brand-royal-blue,
    'border-color': $brand-royal-blue,
    'color': $brand-white,
  ),
  'facebook': (
    'bg-color': $brand-royal-blue,
    'border-color': $brand-royal-blue,
    'color': $brand-white,
  ),
  'flickr': (
    'bg-color': $brand-royal-blue,
    'border-color': $brand-royal-blue,
    'color': $brand-white,
  ),
  'google-plus': (
    'bg-color': $brand-royal-blue,
    'border-color': $brand-royal-blue,
    'color': $brand-white,
  ),
  'instagram': (
    'bg-color': $brand-royal-blue,
    'border-color': $brand-royal-blue,
    'color': $brand-white,
  ),
  'linkedin': (
    'bg-color': $brand-royal-blue,
    'border-color': $brand-royal-blue,
    'color': $brand-white,
  ),
  'pinterest': (
    'bg-color': $brand-royal-blue,
    'border-color': $brand-royal-blue,
    'color': $brand-white,
  ),
  'rss': (
    'bg-color': $brand-royal-blue,
    'border-color': $brand-royal-blue,
    'color': $brand-white,
  ),
  'rss-two': (
    'bg-color': $brand-royal-blue,
    'border-color': $brand-royal-blue,
    'color': $brand-white,
  ),
  'soundcloud': (
    'bg-color': $brand-royal-blue,
    'border-color': $brand-royal-blue,
    'color': $brand-white,
  ),
  'tiktok': (
    'bg-color': $brand-royal-blue,
    'border-color': $brand-royal-blue,
    'color': $brand-white,
  ),
  'twitter': (
    'bg-color': $brand-royal-blue,
    'border-color': $brand-royal-blue,
    'color': $brand-white,
  ),
  'viadeo': (
    'bg-color': $brand-royal-blue,
    'border-color': $brand-royal-blue,
    'color': $brand-white,
  ),
  'youtube': (
    'bg-color': $brand-royal-blue,
    'border-color': $brand-royal-blue,
    'color': $brand-white,
  ),
);
