// Card / *
.card-block {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 0 0 0;

  @media screen and (max-width: $screen-md) {
    padding-top: 16px;
  }
}

.card-title {
  @include make-font-2();
  @include text-line-clamp(3);

  margin-bottom: 16px;
  word-break: break-word;

  a {
    color: $brand-secondary;

    &:hover {
      color: $brand-secondary;
    }

    &:focus {
      outline-offset: unset;
      outline: none;
    }
  }
}

.card-subtitle {
  @include make-font-8();

  margin-bottom: 16px;
  margin-left: auto;
}

.card-text {
  @include make-font-1();
  font-family: $font-family-primary;
  font-size: $font-size-base;
}

.card-group-info {
  display: flex;

  .card-element--webidentities {
    flex: 1;
  }

  .card-btn {
    margin-left: auto;
  }
}

// Card / Spokesperson
.card--spokesperson {
  .card-title {
    margin-bottom: 8px;
  }

  .card-subtitle {
    @include make-font-14();
    color: $brand-primary;
    margin-left: unset;
  }

  .card-text {
    align-items: center;
    display: inline-flex;
    font-size: $font-size-sm;
    gap: 12px;
    letter-spacing: $letter-spacing;
    line-height: 23.4px;
    margin-bottom: 8px;
    word-wrap: break-word;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: $brand-secondary;
    }

    .wizicon {
      @include size(24px, 24px);
    }
  }

  .card-element {
    font-size: $font-size-xs;
    color: $brand-secondary;

    a {
      color: $brand-secondary;
    }
  }

  .card-btn {
    cursor: pointer;
  }

  &.card--alt {
    .card-img {
      margin-bottom: 0;
    }

    .card-block {
      background-color: $brand-light;
      border-radius: 4px;
      padding: $card-spacer-y $card-spacer-x;
    }
  }

  @media screen and (max-width: $screen-md) {
    .card-title {
      margin-bottom: 16px;
    }
  }
}

// Card / Event
.card--event {
  align-items: flex-start;
  border-bottom: 1px solid $brand-platinum;
  justify-content: flex-start;
  padding-bottom: 32px;
  flex-direction: row;

  .card-img {
    height: 160px;
    padding: 0;
    width: 160px;

    img {
      position: static;
    }
  }

  .card-block {
    padding: 0 0 0 24px;

    .card-element--tags {
      .list-item {
        margin-bottom: 8px;
      }

      .label--tags {
        font-family: $font-family-bold;
      }
    }

    .card-title {
      margin-bottom: 8px;
    }

    .card-subtitle {
      align-items: center;
      color: $brand-secondary;
      display: flex;
      font-family: $font-family-bold;
      gap: 8px;
      justify-content: flex-start;
      line-height: 19.20px;
      margin-bottom: 0;
      margin-left: unset;

      .card-element-icon {
        display: flex;
      }

      .wizicon {
        @include size(12px, 12px);

        &-icon-clock {
          @include size(16px, 16px);
        }
      }
    }

    .card-group-info {
      display: flex;
      flex-direction: row;
      gap: 24px;
      margin-bottom: 24px;
    }
  }

  @media screen and (max-width: $screen-md) {
    align-items: unset;
    border: none;
    flex-direction: column;
    justify-content: unset;
    padding-bottom: 0;

    .card-img {
      @include image-view(1, 1);
    }

    .card-block {
      padding: 16px 0 0 0;

      .card-subtitle {
        gap: 4px;

        .card-element-icon {
          .wizicon {
            @include size(12px, 12px);
            margin-top: -2px;

            &-icon-clock {
              @include size(16px, 16px);
              margin-top: 0;
            }
          }
        }
      }

      .card-group-info {
        flex-wrap: wrap;
        gap: 16px;
      }
    }
  }
}

// Card / Performer
.card--performer {
  .card-title {
    font-family: $font-family-secondary;
    font-size: $font-size-xl;
  }

  .card-subtitle {
    color: $text-muted;
  }

  .card-img {
    margin-bottom: $card-spacer-y;

    > img {
      border: 1px solid $brand-secondary;
      border-radius: 50%;
      margin: auto;
    }
  }

  .card-subtitle {
    min-height: $line-height-computed * 2;
    padding-bottom: 20px;
    position: relative;

    &:after {
      border-bottom: 2px solid $brand-primary;
      bottom: 0;
      content: '';
      display: block;
      left: 40%;
      position: absolute;
      width: 20%;
    }
  }

  &.card--grid {
    .card-img {
      width: 140px;
    }

    .card-title,
    .card-subtitle {
      text-align: center;
    }
  }

  &.card--list {
    .card-block {
      padding-left: 0;
      padding-right: 0;

      @media screen and (min-width: $screen-sm-min) {
        padding: 0;
      }
    }
  }
}

.card--topic {
  &.card--default {
    .element-special--tags {
      .list--tags {
        .list-item {
          display: none;

          &:first-child {
            border-right: unset;
            display: inline-flex;
          }
        }
      }
    }
  }

  &.card--alt {
    .element-special--tags {
      .list--tags {
        .list-item {
          display: inline-flex;
        }
      }
    }
  }

  &.card--highlight {
    min-height: 470px;
    overflow: unset;

    .card-img {
      @include image-view(16, 9)
    }

    .list--tags {
      .list-item {
        border-color: $brand-white;
        margin-bottom: 24px;

        .label--tags {
          color: $brand-white;
          font-size: $font-size-sm;
        }

        &:first-child {
          .label--tags.always-first-tag {
            color: $brand-coral;
          }
        }
      }
    }

    .card-block {
      max-width: 49.5%;
      padding: 48px 3.7% 40px 0;
      width: 100%;
      z-index: 2;
    }

    .card-title {
      display: inline-block;
      color: $brand-white;
      font-size: $font-size-xxxl;
      line-height: 56px;
      margin-bottom: 24px;
      padding-bottom: 22px;
      position: relative;

      &::after {
        background-color: $brand-coral;
        bottom: 0;
        content: "";
        height: 6px;
        left: 0;
        position: absolute;
        width: 16px;
      }

      > a {
        @include text-line-clamp(6);
        color: $brand-white;
        font-size: $font-size-xxxl;
        line-height: 56px;
      }
    }

    .card-subtitle {
      color: $brand-white;
      font-size: $font-size-base;
      line-height: 23.4px;
      margin-bottom: 40px;
      margin-left: 0;
    }

    .card-text {
      color: $text-color;
      font-size: $font-size-md;
    }

    .card-btn {
      background-color: $brand-crimson-red;
      margin-left: 0;
      margin-top: 0;
      padding: 24px 32px;
      text-transform: uppercase;

      &:hover {
        background-color: $brand-white;
        color: $brand-primary;

        .card-btn-text {
          color: $brand-primary;
        }
      }

      .card-btn-icon {
        display: none;
      }

      .card-btn-text {
        color: $brand-white;
        font-family: $font-family-bold;
        font-size: $font-size-xs;
        line-height: 16px;
      }
    }

    .mask-card-img {
      max-width: 44.44%;
      position: absolute;
      right: calc((100vw - $container-lg) / 2 * -1);
      top: 95px;
      width: 100%;
      z-index: 3;

      @media screen and (min-width: $screen-md) {
        right: calc((100vw - $container-md) / 2 * -1);
      }

      @media screen and (min-width: $screen-lg) {
        right: calc((100vw - $container-lg) / 2 * -1);
      }

      @media screen and (min-width: $container-xl) {
        right: -83px;
      }
    }

    @media screen and (min-width: 1441px) {
      .card-block {
        max-width: 65%;
      }
    }

    @media screen and (max-width: 1440px) {
      .card-block {
        max-width: 62%;
      }
    }

    @media screen and (max-width: 1367px) {
      .card-block {
        max-width: 63%;
      }
    }

    @media screen and (max-width: $screen-md) {
      flex-direction: column-reverse;
      min-height: auto;
      padding-bottom: 16px;

      &::after {
        content: none;
      }

      .mask-card-img {
        max-width: 100%;
        padding: 0 16px;
        position: relative;
        right: 0;
        top: 0;
        width: 100%;
      }

      .card-block {
        margin-left: 0;
        max-width: 100%;
        padding: 32px 16px;
        width: 100%;
      }

      .list--tags {
        .list-item {
          .label--tags {
            font-size: $font-size-xs;
          }
        }
      }

      .card-title {
        font-size: $font-size-slg;
        line-height: 36.4px;
        margin-bottom: 32px;

        > a {
          font-size: $font-size-slg;
         line-height: 36.4px;
        }
      }

      .card-subtitle {
        font-size: $font-size-sm;
        line-height: 19.2px;
      }
    }
  }
}

// Card Expert
.card--expert {
  .card-title {
    margin-bottom: 32px;
  }

  &:has(.card-subtitle) {
    .card-title {
      margin-bottom: 8px;
    }
  }

  .card-subtitle {
    color: $brand-primary;
    font-family: $font-family-primary;
    font-size: $font-size-base;
    letter-spacing: 0.07px;
    line-height: 23.40px;
    margin: 0 0 32px;
    word-wrap: break-word;
  }

  .card-group-info {
    margin-top: auto;
    min-height: 36px;

    .card-btn {
      margin-top: 0;
    }
  }

  @media screen and (max-width: $screen-md) {
    .card-title {
      margin-bottom: 24px;
    }

    .card-subtitle {
      margin-bottom: 24px;
    }
  }
}

.card--asset {
  .card-element-meta {
    .list--tags {
      .list-item {
        margin-bottom: 8px;
      }
    }

    .label--tags {
      font-family: $font-family-bold;
    }
  }

  .card-subtitle {
    margin-bottom: 8px;
  }

  .card-title {
    margin-bottom: 24px;
  }

  .card-img-icon {
    border-radius: 50%;
    left: 8px;
    position: absolute;
    top: 8px;
    z-index: 3;

    img,
    .wizicon {
      @include size(36px, 36px);

      fill: $brand-crimson-red;
    }
  }

  &.card--alt {
    @include make-card-layout--list(160px, $screen-md);

    flex-direction: row;

    .card-img {
      height: 160px;
      padding: 0;
      width: 160px;

      img {
        height: 100%;
        position: relative;
        width: 100%;
      }
    }

    .card-subtitle {
      margin-bottom: 8px;
      margin-left: unset;
    }

    .card-title {
      margin-bottom: 32px;
    }
  }

  &.card--folder {
    .card-img-icon {
      padding: 8px;
      background-color: $brand-crimson-red;
      border-radius: 50%;
      display: flex;

      img,
      .wizicon {
        @include size(20px, 20px);

        fill: $brand-white;
      }

      > .wizicon path {
        fill: $brand-white;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    .card-element-meta {
      .list--tags {
        .list-item {
          margin-bottom: 16px;
        }
      }
    }

    .card-title,
    .card-subtitle {
      margin-bottom: 16px;
    }

    &.card--alt {
      flex-direction: column;

      .card-img {
        @include image-view(4, 3);
      }

      .card-subtitle {
        margin-bottom: 16px;
      }

      .card-title {
        margin-bottom: 24px;
      }

      .card-block {
        padding: 16px 0 0 0;
      }
    }
  }
}

.card--video-highlight {
  .card-img {
    @include image-view(16, 9);
  }

  .card-block {
    align-items: center;
    background-color: $brand-white;
    bottom: 32px;
    display: flex;
    flex-direction: row;
    padding: 21.5px 24px;
    position: absolute;
    right: 32px;

    .card-title {
      padding: 0;
      margin: 0;
    }

    .card-btn {
      margin: 0 0 0 40px;

      .card-btn-icon {
        background-color: $brand-crimson-red;
        border-color: $brand-crimson-red;
        height: 64px;
        margin-left: 0;
        width: 64px;

        .wizicon {
          path {
            stroke: $brand-white;
          }
        }

        &:hover {
          background-color: $brand-white;
          border-color: $brand-crimson-red;

          .wizicon {
            path {
              stroke: $brand-crimson-red;
            }
          }
        }
      }
    }

    @media screen and (max-width: $screen-md) {
      background: transparent;
      bottom: 16px;
      padding: 0;
      right: 16px;

      .card-title {
        display: none;
      }

      .card-btn {
        margin: 0;
      }
    }
  }
}

.card--clipping {
  border-bottom: 1px solid $brand-platinum;
  padding-bottom: 32px;

  .card-group-info {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-bottom: 16px;

    .card-subtitle {
      font-size: $font-size-sm;
      line-height: 19.20px;
      margin-bottom: 0;
      margin-left: unset;

      &.card-subtitle-organisation {
        color: $brand-primary;
        font-family: $font-family-bold;
      }
    }

    .vertical-line {
      background-color: $brand-secondary;
      height: 13px;
      width: 1px;
    }
  }

  .card-block {
    padding-top: 0;
  }

  @media screen and (max-width: $screen-md) {
    .card-group-info {
      .card-subtitle {
        font-size: $font-size-xs;

        &.card-subtitle-organisation {
          font-size: $font-size-xs;
        }
      }
    }

    .card-btn {
      margin-top: 0
    }
  }
}

.card--feature {
  @include make-card-layout--list(40px);

  .card-img {
    padding: 5px;

    .wizicon {
      @include size(30px, 30px);
    }

    img {
      @include size(30px, auto);
    }
  }
  @media screen and (max-width: $screen-xs) {
    .card-block {
      margin-left: 50px;
    }
  }

  .card-title {
    @include make-font-10();
    color: $brand-dark;
    display: inline-block;
    font-weight: $font-weight-regular;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

// Cards
.card--feed-facebook {
  @include card-sizing(10px, 10px);
  @include make-card-layout--list(40px);

  background-color: $brand-light;
  padding: 15px;

  .card-img {
    margin-left: 0;
  }

  .card-title {
    font-family: $font-family-primary;
    font-size: $font-size-base;
    font-weight: 700;
  }

  .card-title-aside {
    color: $text-muted;
    font-size: $font-size-sm;
    font-weight: 400;
  }

  .card-subtitle {
    text-transform: inherit;
  }

  .card-text {
    color: $text-color;
    font-family: $font-family-primary;
  }

  @media screen and (max-width: $screen-sm) {
    .card-img {
        float: left;
        margin-right: 15px;
        width: 40px;

        > img {
          width: 40px;
      }
    }

    .card-block {
      overflow: hidden;
    }
  }
}
